<template>
    <component :is="isDesktop?'VDialog':'VBottomSheet'"
               v-model="joinDialog"
               persistent
               scrollable
               :max-width="isDesktop?'400px':'100%'"
    >
        <template v-slot:activator="{on,attrs}">
            <slot name="activator"
                  :on="on"
                  :attr="attrs"></slot>
        </template>
        <v-card class="joinDialog">
            <v-card-title>
                <div>{{ $t('Join เป็นสมาชิกร้านค้า') }}</div>
                <v-spacer></v-spacer>
                <v-btn icon
                       @click="joinDialog = false">
                    <v-icon color="primary">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="px-4">
                <!--                <div class="mb-4 grey&#45;&#45;text">-->
                <!--                    เพื่อผลประโยชน์สูงสุดของคุณ กรุณาอ่านรายละเอียดก่อนการยืนยัน-->
                <!--                </div>-->
                <v-alert type="info"
                         color="primary"
                         dense
                         text
                         class="px-4"
                         style="font-size: 0.8rem;">
                    <b>{{ $t('ยินดีต้อนรับ') }}</b> {{ $t('และขอขอบคุณที่ท่านต้องการสมัครเป็นสมาชิกร้านค้าของเรา กรุณาอ่านเงื่อนไขดังต่อไปนี้') }}
                </v-alert>
                <v-card elevation="24">
                    <v-list dense>
                        <v-list-item class="px-3">
                            <v-list-item-icon>
                                <v-icon color="success">mdi-account-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="white-space: normal; overflow: visible;">
                                    <span>{{ $t('ได้รับสิทธิพิเศษส่วนลดตามที่ร้านค้ากำหนด') }}</span>
                                    <v-tooltip class="d-inline-block mr-1"
                                               top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small
                                                    v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        {{ $t('ทั้งนี้ขึ้นกับระดับสมาชิกที่ร้านกำหนดให้คุณต่อไป') }}
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-3 pt-0">
                            <v-list-item-icon>
                                <v-icon color="warning">mdi-shield-account-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="white-space: normal; overflow: visible;">
                                    <span>{{ $t('อนุญาตให้ร้านเห็นข้อมูลส่วนตัวของฉัน') }} <v-tooltip class="d-inline-block mr-1"
                                                                                          top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small
                                                    v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        {{ $t('คุณสามารถยกเลิกการสมัครเป็นสมาชิกนี้ได้ทุกเมื่อ') }}
                                    </v-tooltip></span>
                                    <div class="minor">{{ $t('ชื่อ-สกุล อีเมล เบอร์โทรศัพท์ เพศ ของบัญชีนี้') }}</div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-3 pt-0">
                            <v-list-item-icon>
                                <v-icon color="primary">mdi-email-newsletter</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title style="white-space: normal; overflow: visible;">
                                    <span>{{ $t('รับอีเมลข่าวและโปรโมชั่นจากร้านค้า') }}</span>
                                    <v-tooltip class="d-inline-block mr-1"
                                               top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small
                                                    v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        {{ $t('คุณสามารถยกเลิกการสมัครเป็นสมาชิกนี้ได้ทุกเมื่อ') }}
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-btn :class="(!isDesktop)?'w-100':''"
                       x-large
                       block
                       color="primary"
                       @click="onClickJoin">
                    {{ $t('ฉันยอมรับและยืนยันเป็นสมาชิกร้าน') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </component>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapGetters} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "JoinComponent",
    props: {
        gotoNextStep: {
            type: Function,
        },
        fetchMyOrderList: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            joinDialog: false,
        }
    },
    mixins: [
        MixinsHelper,
    ],
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet
    },
    computed: {
        ...mapGetters({
            isShowFormJoin: 'order/isShowFormJoin',
        }),
    },
    methods: {
        ...mapActions({
            'joinMember': 'order/joinMember',
            'fetchOrder': 'order/fetchOrder',
            'actionFetchOrders': "myOrders/fetchOrders",
        }),

        async onClickJoin() {
            try {
                const previous_value_isShowFormJoin = this.isShowFormJoin;
                //  1. Join Action
                await this.joinMember();
                if(!this.fetchMyOrderList){
                    await this.fetchOrder({skipUpdateStepMetasOpen: true});
                }else{
                    await this.actionFetchOrders();
                }
                this.joinDialog = false;
                //  2. nextStep ของ BuyerInfo ถ้าอยู่ในขั้นตอน requiredJoin (cpIsShowFormJoin = true)
                if (previous_value_isShowFormJoin) {
                    this.gotoNextStep();
                }
            }
            catch (e) {
                //
            }
        },
    },
}
</script>
<style scoped></style>